
import { mapState } from 'vuex'
export default {
    name: 'Default',
    middleware: ['auth', 'portalPermissions', 'removeRoutes'],
    data: () => ({
        title: 'Control Panel',
        conformDialog: false,
        drawer: true,
        screenHeight: 500,
        activeItems: {
            home: true,
        },
    }),
    async fetch() {
        await this.getLanguageString()
    },
    computed: {
        ...mapState({
            items: (state) => state.settings.menubar,
            myProfile: (state) => state.users.myProfile,
            masterLanguageString: (state) =>
                state.internal.master.languagesStrings,
        }),
    },
    mounted() {
        if (!this.$cookie.get('_theme_type')) {
            this.$cookie.set('_theme_type', 'default')
        }
        this.setMenubarActiveAccordingToRoutePath()
        if (this.$cookie?.get('locale')) {
            this.$store.commit(
                'settings/setLanguage',
                this.$cookie.get('locale')
            )
        }
        // if (this.masterLanguageString.length === 0) {
        //     this.$store.dispatch('internal/master/unAuthLanguagesStrings')
        // }
    },
    methods: {
        goToHome() {
            this.$router.push({ path: '/home/slider' })
        },
        onResize() {
            const screenHeight = window.innerHeight
            // 40 is the bottom Nav height
            // 28 is the Padding Top
            this.screenHeight = screenHeight - 40 - 28
        },
        logout() {
            this.conformDialog = false
            this.$cookie.remove('token')
            this.$cookie.remove('_theme_type')
            this.$cookie.remove('user')
            this.$router.push('/login')
        },
        getCurrentLocale(localeCode) {
            if (!localeCode) return null
            const currentLocaleData = this.languages.find(
                (el) => el.code === localeCode
            )
            if (!currentLocaleData) return null
            return currentLocaleData.flag
        },
        onSwitchLocale() {
            const locale = this.language
            const getCurrentLocaleIndex = this.languages.findIndex(
                (el) => el.code === locale
            )
            const nextIndex = getCurrentLocaleIndex + 1
            let nextLocale = null
            // Check if the index of the current locale is out of the array length
            // Then starting index from zero
            if (this.languages[nextIndex] === undefined) {
                const nextIndexAfterOutOfLength =
                    nextIndex % this.languages.length
                nextLocale = this.languages[nextIndexAfterOutOfLength].code
            } else {
                nextLocale = this.languages[nextIndex].code
            }

            this.$cookie.set('locale', nextLocale)
            this.$store.commit('settings/setLanguage', nextLocale)
        },
        setMenubarActiveAccordingToRoutePath() {
            // get the current menubar tab to active according to the url route
            // If you wanna know how it works, delete below code, and reload
            let findCurrentMenu = null
            const currentPath = this.$nuxt.$route.path?.split('/').at(1)
            if (currentPath) {
                this.items.forEach((item) => {
                    if (item?.children?.length) {
                        item.children.forEach((el) => {
                            const path = el?.to?.split('/')
                            if (path[1] && path[1] === currentPath) {
                                findCurrentMenu = item.title
                            }
                        })
                    }
                })
            }
            // if found current menu, set the active item to be true
            if (findCurrentMenu) {
                const items = {}
                items[findCurrentMenu] = true
                this.activeItems = { ...items }
            }
        },

        async getLanguageString() {
            if (this.masterLanguageString.length === 0) {
                await this.$store.dispatch('internal/master/languagesStrings')
            }
        },
    },
}
