
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'Portal',
    middleware: ['auth', 'portalPermissions', 'languages', 'removeRoutes'],
    data: () => ({
        title: 'Control Panel',
        conformDialog: false,
        drawer: true,
        screenHeight: 500,
        activeItems: {
            home: true,
        },
    }),
    async fetch() {
        await this.getLanguageString()
    },
    computed: {
        ...mapState({
            myProfile: (state) => state.internal.users.profile,
            masterLanguageString: (state) =>
                state.internal.master.languagesStrings,
        }),
        ...mapGetters({
            items: 'internal-portal-setting/menubarInternalPortalWithPermissions',
        }),
    },
    mounted() {
        // const routesToKeep = this.$router.options.routes.filter(
        //     (route) =>
        //         route.path.includes('/internal-portal') ||
        //         route.path === '/login'
        // )

        // // Clear existing routes
        // this.$router.matcher = new this.$router.constructor({
        //     mode: this.$router.options.mode,
        //     routes: [],
        // }).matcher

        // // Add the route(s) you want to keep
        // this.$router.addRoutes(routesToKeep)

        if (!this.$cookie.get('_theme_type')) {
            this.$cookie.set('_theme_type', 'portal')
        }
        // to get user profile
        this.$store.dispatch('internal/users/getProfile')
        this.setMenubarActiveAccordingToRoutePath()
    },
    methods: {
        goToHome() {
            this.$router.push({
                path: '/internal-portal/operator-financial-report',
            })
        },
        onResize() {
            const screenHeight = window.innerHeight
            // 40 is the bottom Nav height
            // 28 is the Padding Top
            this.screenHeight = screenHeight - 40 - 28
        },
        async logout() {
            this.conformDialog = false
            await this.$axios.get('/users/logout/session')
            this.$cookie.remove('_theme_type')
            this.$cookie.remove('token')
            this.$cookie.remove('user')
            await this.$router.push('/login')
            this.$router.go(0)
        },
        getCurrentLocale(localeCode) {
            if (!localeCode) return null
            const currentLocaleData = this.languages.find(
                (el) => el.code === localeCode
            )
            if (!currentLocaleData) return null
            return currentLocaleData.flag
        },
        onSwitchLocale() {
            const locale = this.language
            const getCurrentLocaleIndex = this.languages.findIndex(
                (el) => el.code === locale
            )
            const nextIndex = getCurrentLocaleIndex + 1
            let nextLocale = null
            // Check if the index of the current locale is out of the array length
            // Then starting index from zero
            if (this.languages[nextIndex] === undefined) {
                const nextIndexAfterOutOfLength =
                    nextIndex % this.languages.length
                nextLocale = this.languages[nextIndexAfterOutOfLength].code
            } else {
                nextLocale = this.languages[nextIndex].code
            }

            this.$cookie.set('locale', nextLocale)
            this.$store.commit('settings/setLanguage', nextLocale)
        },
        setMenubarActiveAccordingToRoutePath() {
            // get the current menubar tab to active according to the url route
            // If you wanna know how it works, delete below code, and reload
            let findCurrentMenu = null
            const currentPath = this.$nuxt.$route.path?.split('/').at(1)
            if (currentPath) {
                this.items.forEach((item) => {
                    if (item?.children?.length) {
                        item.children.forEach((el) => {
                            const path = el?.to?.split('/')
                            if (path[1] && path[1] === currentPath) {
                                findCurrentMenu = item.title
                            }
                        })
                    }
                })
            }
            // if found current menu, set the active item to be true
            if (findCurrentMenu) {
                const items = {}
                items[findCurrentMenu] = true
                this.activeItems = { ...items }
            }
        },
        async getLanguageString() {
            if (this.masterLanguageString.length === 0) {
                await this.$store.dispatch('internal/master/languagesStrings')
            }
        },
    },
}
