
export default {
    props: {
        model: {
            type: Boolean,
            default: false,
        },
        copyText: {
            type: String,
            default: '',
        },
        successIcon: {
            type: Boolean,
            default: false,
        },
        titleText: {
            type: String,
            default: '',
        },
        titleBackgroundClass: {
            type: String,
            default: '',
        },
        titleTextClass: {
            type: String,
            default: '',
        },
        messageText: {
            type: String,
            default: '',
        },
        rejectBtnText: {
            type: String,
            default: '',
        },
        rejectBtnClass: {
            type: String,
            default: '',
        },
        acceptBtnText: {
            type: String,
            default: '',
        },
        acceptBtnClass: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        checkDialog: false,
    }),
    watch: {
        model(n, o) {
            this.checkDialog = n
        },
    },
    mounted() {
        this.checkDialog = this.model
    },
    methods: {
        rejectEvent() {
            this.checkDialog = false
            this.$emit('reject', true)
        },
        acceptEvent() {
            this.checkDialog = false
            this.$emit('accept', true)
        },
        doCopyText() {
            navigator.clipboard.writeText(this.copyText)
            const textArea = document.createElement('textarea')
            textArea.value = this.copyText

            // Avoid scrolling to bottom
            textArea.style.top = '0'
            textArea.style.left = '0'
            textArea.style.position = 'fixed'
            textArea.style.display = 'none'

            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()

            try {
                const successful = document.execCommand('copy')
                const msg = successful
                    ? 'Password copied'
                    : 'Unable to copy password'
                this.$toast.success(msg)
            } catch (err) {
                this.$toast.success('Unable to copy password')
            }

            document.body.removeChild(textArea)
        },
    },
}
