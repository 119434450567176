
const OPERATOR_FINANCIAL_REPORT = "operator_financial_report";
const OPERATOR_HR_MONITORING = "operator_hr_monitoring";
const LCM_INTERNAL_HR = "lcm_internal_hr";
const USER_MANAGEMENT = "user_management";
const USER_ACTIVITY = "user_activity";
const CMS = "cms";
const APPLICATION_TRACKING = "application_tracking";

const allowedRoutes = [
	{ code: OPERATOR_FINANCIAL_REPORT, route: '/internal-portal/operator-financial-report' },
	{ code: OPERATOR_HR_MONITORING, route: '/internal-portal/operator-hr-monitoring' },
	{ code: LCM_INTERNAL_HR, route: '/internal-portal/lcm-internal-hr' },
	{ code: USER_MANAGEMENT, route: '/internal-portal/user-management' },
	{ code: USER_ACTIVITY, route: '/internal-portal/user-activity' },
	{ code: APPLICATION_TRACKING, route: '/internal-portal/application-tracking' }
];

const checkPermissionAndRedirect = (rolePermissions, code, routeToRedirect, redirect) => {
	const allowed = rolePermissions.find(el => el.permission_code === code && el.can_view);
	if (allowed) {
		redirect(routeToRedirect);
		return true;
	}
	return false;
};

export default function ({ route, redirect, $cookie, store }) {
	const rolePermissions = store.state.internal['role-permissions'].rolePermissions;
	const path = route.path



	// If it's CMS route
	if (!path.includes('/internal-portal')) {
		const findCmsPermissions = rolePermissions.find(el => el.permission_code === CMS && el.can_view)
		if (findCmsPermissions) {
			return;
		} else {
			$cookie.remove('_theme_type')
			redirect('/')
		}
	}

	// Since the player profile can be accessible for all the users
	// That's why I ignore this path
	if (path === '/internal-portal/profile') return;

	// Check if the next route has permission
	const findPermissionCode = allowedRoutes.find(el => {
		if (path.includes(el.route)) return true;
		return false
	})
	if (findPermissionCode) {
		const allowed = rolePermissions.find(el => el.permission_code === findPermissionCode.code && el.can_view);
		if (allowed) return;
	}


	for (const { code, route } of allowedRoutes) {
		if (checkPermissionAndRedirect(rolePermissions, code, route, redirect)) return;
	}

	$cookie.remove('_theme_type')
	redirect('/')
}
