
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            menubar: (state) =>
                state['internal-portal-setting'].titleAndPathInternalPortal,
        }),
        currentPathTitle() {
            const findPathFromStore = this.menubar.find((el) => {
                const cloneEl = JSON.parse(JSON.stringify(el))

                // Prepare the regexp
                const regexPattern = cloneEl.path.replace(/\*/g, '.*')
                const regex = new RegExp(`^${regexPattern}$`)

                return regex.test(this.$route.path)
            })
            if (findPathFromStore)
                return {
                    title: findPathFromStore.title,
                    breadcrumbs: findPathFromStore.lists,
                }
            return { title: '', breadcrumbs: [] }
        },
    },
    methods: {
        navigation(link, isGoBack = false) {
            if (link && !isGoBack) {
                this.$router.push(link)
                return
            }
            this.$router.go(-1)
        },
    },
}
