
import { mapState } from 'vuex'
export default {
    name: 'Blank',
    middleware: ['languages'],
    async fetch() {
        await this.fetchLanguageString()
    },
    computed: {
        ...mapState({
            masterLanguageString: (state) =>
                state.internal.master.languagesStrings,
        }),
    },
    mounted() {
        // this.fetchLanguageString()
    },
    methods: {
        async fetchLanguageString() {
            if (this.masterLanguageString.length === 0) {
                await this.$store.dispatch(
                    'internal/master/unAuthLanguagesStrings'
                )
            }
        },
    },
}
